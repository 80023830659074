export class UtilsService {

  // if an array has three elements, shuffling from 0 to 2 shuffles the whole array
  public static shuffle(array: Array<any>, from?: number, to?: number) {
    const shuffleFrom = (from === undefined) ? 0 : Math.min(from, array.length - 1);
    const shuffleTo = (to === undefined) ? (array.length - 1) : Math.min(to, array.length - 1);

    if ((shuffleTo - shuffleFrom) > 1) {
      for (let i = shuffleFrom; i <= shuffleTo; i++) {
        const j = shuffleFrom + Math.floor((shuffleTo - shuffleFrom + 1) * Math.random());
        if (i !== j) {
          const swap = array[i];
          array[i] = array[j];
          array[j] = swap;
        }
      }
    }
  }
}
