import { makeAutoObservable, runInAction } from 'mobx';
import { Utterance, UtteranceType } from '../models/utterance.model';
import { GoogleImageService } from '../services/googleImages.service';
import clarifaiService from '../services/clarifai.service';
import { configService, makeAbsoluteUrl } from '../services/config.service';

export enum ConversationState {
  WAITING_FOR_INPUT,
  LOADING_INPUT,
  LOADING_AI
}

export class ConversationStore {
  utterances: Utterance[] = [];
  state: ConversationState = ConversationState.WAITING_FOR_INPUT;
  private timeoutId: NodeJS.Timeout | null = null;

  constructor() {
    makeAutoObservable(this);
    // add the initial prompt image
    this.add(new Utterance(UtteranceType.FROM_AI, [makeAbsoluteUrl('/assets/initial.png')]));
  }

  setState = (state: ConversationState) => {
    this.state = state;
  };

  add = (utterance: Utterance) => {
    this.utterances.push(utterance);
  };

  handleUserSelectFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = (loadEvent: ProgressEvent<FileReader>) => {
      if (loadEvent.target?.result) {
        this.handleUserSelectImage(loadEvent.target.result as string);
      }
    };
    reader.readAsDataURL(file);
  };

  handleUserSelectImage = async (
    imageUrl: string,
    isStory?: boolean,
    clearHistory?: boolean,
    logType?: string
  ) => {
    if (clearHistory) {
      clarifaiService.clearKeywordHistory();
    }

    let imageSrc = null;
    if (!isStory) {
      const utterance = new Utterance(UtteranceType.FROM_HUMAN, [imageUrl]);
      this.add(utterance);
      const base64Pos = utterance.imageUrl.indexOf('base64,');
      const base64 = utterance.imageUrl.substr(base64Pos + 7);
      imageSrc = { base64: base64 };
    } else {
      imageSrc = imageUrl.replace('http://localhost:3000', 'https://speakinginpictures.com');
    }

    this.setState(ConversationState.LOADING_AI);

    try {
      const clarifaiResult = await clarifaiService.getKeywords(imageSrc);
      const imageSearchUrl = GoogleImageService.getSearchUrl(clarifaiResult.keywords);
      const images = await GoogleImageService.getImageSearchResults(imageSearchUrl ?? '');

      runInAction(() => {
        this.setState(ConversationState.WAITING_FOR_INPUT);
        const utteranceAI = new Utterance(UtteranceType.FROM_AI, images);
        this.add(utteranceAI);
      });

      // Log utterance - skipped for now, as it's broken
      // const logDetails = {
      //   imageFromUser: imageUrl,
      //   imageFromAI: images[0],
      //   clarifaiResults: JSON.stringify(clarifaiResult),
      //   imageSearchUrl: imageSearchUrl,
      //   logType: logType
      // };

      // await fetch('logUtterance', {
      //   method: 'PUT',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify(logDetails)
      // });

    } catch (error) {
      runInAction(() => {
        this.setState(ConversationState.WAITING_FOR_INPUT);
        const utteranceAI = new Utterance(UtteranceType.FROM_AI, [configService.config.noImageFoundUrl]);
        this.add(utteranceAI);
      });
    }
  };

}

export const conversationStore = new ConversationStore();