import { Output } from './types';
import { UtilsService } from './utils.service';
import { configService } from './config.service';

// Remove Clarifai import and replace with types
interface ClarifaiResponse {
  results: Array<{
    outputs: Output[];
  }>;
}

class ClarifaiResult {
  keywords: string = '';
  top25: Array<{ name: string, value: number }> = [];
}

export class ClarifaiService {
  private lastTopKeyword: String | null = null;
  private lastKeywords: Array<String> = [];

  public async getKeywords(srcImage: any): Promise<ClarifaiResult> {
    const config = configService.config;

    try {
      const input = {
        data: {
          image: {
          }
        }
      } as any;

      if (typeof srcImage === 'string') {
        if (srcImage.startsWith('http')) {
          input.data.image.url = srcImage;
        } else {
          input.data.image.base64 = await this.toBase64(srcImage);
        }
      } else {
        input.data.image.base64 = srcImage.base64;
      }
    
      const response = await fetch('/getKeywords', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(input)
      });

      if (!response.ok) {
        throw new Error(`Clarifai API error: ${response.statusText}`);
      }

      const data: ClarifaiResponse = await response.json();
      
      // flatten the results
      // const arrayConcepts = this.flattenConcepts(data.results[0].outputs);
      const arrayConcepts = this.flattenConcepts(data.results[0].outputs);
      
      // sort by value
      arrayConcepts.sort((a: any, b: any): number => {
        return b.value - a.value;
      });
      
      // take the specified number of concepts
      if (arrayConcepts.length > 1) {
        // shuffle if specified
        UtilsService.shuffle(arrayConcepts, config.shuffleKeywordsFrom - 1, config.shuffleKeywordsTo - 1);
      }

      const keywords: Array<String> = [];
      const usedMap: Record<string, number> = {};
      const result = new ClarifaiResult();
      let numNegated = 0;
      let numDup = 0;
      let newTopKeyword = '';

      // filter and sort keywords
      const lastKeywords: Set<String> = new Set(this.lastKeywords);

      const ignoreOffset = config.keywordOffsetMin +
        Math.floor(Math.random() * (config.keywordOffsetMax - config.keywordOffsetMin + 1));
      console.log('Starting at keyword offset: ' + ignoreOffset);

      for (let i = ignoreOffset;
        (i < arrayConcepts.length) && (result.top25.length < 25 || keywords.length < config.keywordLimit); i++) {
        const name = arrayConcepts[i].name.replace('&', 'and');
        if (usedMap.hasOwnProperty(name.toLowerCase())) {
          continue;
        } else {
          usedMap[name.toLowerCase()] = 1;
        }

        if (keywords.length < config.keywordLimit) {
          let keyword = (name.indexOf(' ') === -1) ? name : ('"' + name + '"');
          if (keyword === this.lastTopKeyword) {
            // if this keyword is going to be inserted from the previous query, don't repeat it
            continue;
          }

          // optionally negate this keyword
          let negated = false;
          if (keywords.length < config.negatedKeywordProbabilities.length) {
            negated = (Math.random() < config.negatedKeywordProbabilities[keywords.length])
              && (numNegated < config.maxNegatedKeywords);
            if (negated) {
              keyword = '-' + keyword;
              ++numNegated;
            }
          }

          if (!negated && newTopKeyword === '') {
            newTopKeyword = keyword;
          }

          const isDup = lastKeywords.has(keyword);
          if (numDup < config.dupKeywordLimit || !isDup) {
            keywords.push(keyword);
          } else {
            console.log('Excluded repeated keyword ' + keyword);
          }
          if (isDup) {
            ++numDup;
          }
        }
        if (result.top25.length < 25) {
          console.log('#' + (result.top25.length + 1) + ': ' + arrayConcepts[i].name + ', ' + arrayConcepts[i].value);
          result.top25.push({ name: arrayConcepts[i].name, value: arrayConcepts[i].value });
        }
      }

      if (this.lastTopKeyword) {
        if (keywords.length === config.keywordLimit) {
          keywords.pop();
        }
        keywords.push(this.lastTopKeyword);
        console.log('Included previous keyword ' + this.lastTopKeyword);
      }
      this.lastTopKeyword = newTopKeyword;
      this.lastKeywords = keywords;
      result.keywords = keywords.join(' ');
      console.log('keywords = ' + result.keywords);
      return result;
    } catch (error) {
      console.error('Clarifai API error:', error);
      throw error;
    }
  }

  private async toBase64(input: any): Promise<string> {
    if (typeof input === 'object' && input.base64) {
      return input.base64;
    }

    // If input is already a base64 string, return it
    if (typeof input === 'string') {
      // Check if it's already a base64 string
      if (input.startsWith('data:') || /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/.test(input)) {
        return input.split(',')[1] || input;
      }
    }

    // If input is an ArrayBuffer, convert it directly
    if (input instanceof ArrayBuffer) {
      const bytes = new Uint8Array(input);
      const binary = bytes.reduce((acc, byte) => acc + String.fromCharCode(byte), '');
      return btoa(binary);
    }

    // If input is a File or Blob, use FileReader
    if (input instanceof Blob || input instanceof File) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(input);
        reader.onload = () => {
          const base64String = reader.result as string;
          // Remove the data URL prefix (e.g., "data:image/jpeg;base64,")
          resolve(base64String.split(',')[1]);
        };
        reader.onerror = error => reject(error);
      });
    }

    throw new Error('Unsupported input type for base64 conversion');
  }

  public clearKeywordHistory(): void {
    this.lastTopKeyword = null;
  }

  private flattenConcepts(srcObj: Output[], concepts?: Array<any>, multiplier?: number): Array<any> {
    if (!concepts) {
      concepts = [];
    }

    srcObj.forEach(output => {
      if (output.model) {
          const modelName = output.model.display_name ? output.model.display_name.toLowerCase() : '';
          if (configService.config.modelMultipliers.hasOwnProperty(modelName)) {
            multiplier = configService.config.modelMultipliers[modelName];
            console.log('applying model multiplier for ' + modelName + ' to ' + multiplier);
          }
        }
        if (output.data.concepts) {
          output.data.concepts.forEach(concept => {
            const lcConcept = concept.name.toLowerCase();
            if (!configService.excludedConceptSet.hasOwnProperty(lcConcept)) {
              concepts!.push({
                name: concept.name,
                value: concept.value * ((multiplier === undefined) ? 1 : multiplier)
              });
            } else {
              console.log('filtered out ' + lcConcept);
            }
          });
        }
      });
    return concepts;
  }
}

// Create and export a singleton instance
const clarifaiService = new ClarifaiService();
export default clarifaiService;
