// import './foundation.css';
// import './App.css';
import { Route, Routes } from 'react-router-dom';
import { About } from './components/about/About';
import Main from './components/main/Main';
import { Logs } from './components/logs/Logs';

function App() {
  return (
    
    <div className="App">
          <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/logs" element={<Logs />} />
    </Routes>

      <About />
      </div>
  );
}



export default App;
