import Modal from 'react-modal';

Modal.defaultStyles.overlay!.backgroundColor = 'rgba(0, 0, 0, 0.5)';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: 'calc(100% - 20px)',
    height: 'calc(100% - 20px)',
  },
};

type ZoomProps = {
  imageUrl: string;
  isOpen: boolean;
  onClose: () => void;
}

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

export function Zoom(props: ZoomProps) {

  const { imageUrl, isOpen, onClose } = props;

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        style={customStyles}
        contentLabel="Example Modal"
      >

        <button onClick={onClose} style={{ float: 'right', color: 'black' }}>
          &#10006;
        </button>

        <img src={imageUrl} height="100%" width="100%" alt="zoom" />
      </Modal>
  </div>

  );
}