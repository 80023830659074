import { observer } from 'mobx-react-lite';
import Grid from '../grid/Grid';
import { conversationStore } from '../../stores/conversation.store';
import { configService } from '../../services/config.service';
import './Main.css';

const Main = observer(() => {
  const { inited } = configService;
  if (!inited) {
    return <div>Loading...</div>;
  }
  return (
    <div className="grid-frame hide-logo">
      <div 
        className="bubble-scroll-container grid-block collapse medium-12 large-12 vertical" 
        style={{ minWidth: '560px' }}
      >
        <Grid conversationStore={conversationStore} />
      </div>
    </div>
  );
});

export default Main;