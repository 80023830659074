import { configService } from './config.service';
import { ConversationState, conversationStore } from '../stores/conversation.store';
import * as exif from 'exif-js';

class UploadImageService {
  onImageChanged(e: any) {
    const files: FileList = e.target.files;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.type.match('image.*')) {
        this.handleUserSelectFile(file);
        break;
      }
    }
  }

  private handleUserSelectFile(file: File) {
    conversationStore.setState(ConversationState.LOADING_INPUT);
    // remove upload prompt
    // this.conversationStore.removeByType(UtteranceType.PROMPT_IMAGE);

    const MAX_DIMENSIONS = configService.config.maxImageDimensions;
    const reader = new FileReader();
    // this.conversationStore.add(new Utterance(UtteranceType.LOADING_HUMAN_IMAGE));

    reader.onload = (loadEvent: any) => {
    // this.conversationStore.removeByType(UtteranceType.LOADING_HUMAN_IMAGE);
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d')!;

      const imageDataUrl: string = loadEvent.target.result;
      const image = new Image();
      image.src = imageDataUrl;
      image.onload = () => {

        exif.getData(image as any, function (this: { exifdata: any }) {
          // resize the image as necessary
          if (image.height > MAX_DIMENSIONS) {
            image.width *= MAX_DIMENSIONS / image.height;
            image.height = MAX_DIMENSIONS;
          }
          if (image.width > MAX_DIMENSIONS) {
            image.height *= MAX_DIMENSIONS / image.width;
            image.width = MAX_DIMENSIONS;
          }
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          canvas.width = image.width;
          canvas.height = image.height;
          ctx.drawImage(image, 0, 0, image.width, image.height);

          // https://stackoverflow.com/questions/19463126/how-to-draw-photo-with-correct-orientation-in-canvas-after-capture-photo-by-usin
          const orientation = this.exifdata.Orientation;
          if (orientation) {
            ctx.save();
            const width = canvas.width;
            const styleWidth = canvas.style.width;
            const height = canvas.height;
            const styleHeight = canvas.style.height;

            if (orientation > 4) {
              canvas.width = height; canvas.style.width = styleHeight;
              canvas.height = width; canvas.style.height = styleWidth;
            }
            switch (orientation) {
              case 2: ctx.translate(width, 0); ctx.scale(-1, 1); break;
              case 3: ctx.translate(width, height); ctx.rotate(Math.PI); break;
              case 4: ctx.translate(0, height); ctx.scale(1, -1); break;
              case 5: ctx.rotate(0.5 * Math.PI); ctx.scale(1, -1); break;
              case 6: ctx.rotate(0.5 * Math.PI); ctx.translate(0, -height); break;
              case 7: ctx.rotate(0.5 * Math.PI); ctx.translate(width, -height); ctx.scale(-1, 1); break;
              case 8: ctx.rotate(-0.5 * Math.PI); ctx.translate(-width, 0); break;
            }
            ctx.drawImage(image, 0, 0, image.width, image.height);
            ctx.restore();
          }

          const base64 = canvas.toDataURL('image/jpeg');
          // self.conversationStore.removeByType(UtteranceType.LOADING_HUMAN_IMAGE);
          conversationStore.handleUserSelectImage(base64, false, false, 'upload');
        });

      };
    };
    reader.readAsDataURL(file);
  }

}

export const uploadImageService = new UploadImageService();