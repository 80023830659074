import React, { useEffect } from 'react';
import Modal from 'react-modal';

Modal.defaultStyles.overlay!.backgroundColor = 'rgba(0, 0, 0, 0.5)';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: 'calc(100% - 20px)',
    height: 'calc(100% - 20px)',
    // width: '100%',
    // height: '100%',
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

export function About() {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  useEffect(() => {
    (window as any).showAboutDialog = () => {
      setIsOpen(true);
    };
  }, []);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >

        <button onClick={closeModal} style={{ float: 'right', color: 'black' }}>
          &#10006;
        </button>

        <div style={{
          color: 'black',
          fontSize: 40,
          overflow: 'hidden',
          fontFamily: 'sans-serif',
          fontWeight: 900
        }}>
    <p style={{textAlign: "center"}}>
      <img src="assets/logo.png" alt="logo"/>
    </p>
    <p>AI chatbot for pictures only.</p>
    <p>By <a href="http://briankane.net/">Brian Kane</a>
    </p>
  </div>
{/* 
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2>
        <button onClick={closeModal}>close</button>
        <div>I am a modal</div>
        <form>
          <input />
          <button>tab navigation</button>
          <button>stays</button>
          <button>inside</button>
          <button>the modal</button>
        </form> */}
      </Modal>
    </div>
  );
}

export function showAboutDialog() {
  (window as any).showAboutDialog();
}
// ReactDOM.render(<App />, appElement);