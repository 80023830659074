import axios from 'axios';
// React Hook for using the service
import { useState, useCallback } from 'react';
import { configService } from './config.service';

export class GoogleImageService {
  private static GOOGLE_SEARCH_APP_ID = '004288158005064593219:4lh5e_-z0ds';
  private static GOOGLE_SEARCH_API_KEY = 'AIzaSyDC-K2CzYT5Po95iqT25EQNuGRIa8EENPc';
  private static usedImageUrls: Record<string, boolean> = {};

  static markAsUsed(link: string): void {
    this.usedImageUrls[link] = true;
  }

  static getSearchUrl(searchTerm: string, config = configService.config): string | null {
    const start = 0;
    const num = config.numberImageResults;
    
    if (!searchTerm) {
      return null;
    }
    
    let parameters = '&q=' + encodeURIComponent(searchTerm);
    parameters += '&searchType=image';
    parameters += start ? '&start=' + start : '';
    parameters += '&num=' + num;
    
    if (config.searchImageSizeXLarge) {
      parameters += '&imgSize=xlarge';
    }

    const options = {
      host: 'www.googleapis.com',
      path: '/customsearch/v1?key=' + this.GOOGLE_SEARCH_API_KEY + '&cx=' + this.GOOGLE_SEARCH_APP_ID + parameters
    };

    return 'https://' + options.host + options.path;
  }

  static async getImageSearchResults(url: string, config = configService.config): Promise<string[]> {
    try {
      const { data } = await axios.get(url);
      const result: string[] = [];

      if (!data.items || !data.items.length) {
        result.push(config.noImageFoundUrl);
        return result;
      }

      const imageFilter = (item: any) => {
        if (this.usedImageUrls[item.link]) {
          return false;
        }
        const extension: string = item.link.substring(item.link.lastIndexOf('.') + 1, item.link.length) || item.link;
        return config.allowedImageExtensions.indexOf(extension.toLowerCase()) !== -1;
      };

      let items = data.items.filter(imageFilter) as any[];

      if (items.length < 3) {
        console.log('exhausted unique images, reusing');
        this.usedImageUrls = {};
        items = data.items.filter(imageFilter);
      }

      items.forEach(element => {
        result.push(element.link);
      });

      if (config.shuffleImageResults) {
        this.shuffle(result);
      }

      result.push(config.noImageFoundUrl);
      return result;
    } catch (error) {
      console.error('Error fetching image results:', error);
      return [config.noImageFoundUrl];
    }
  }

  private static shuffle<T>(array: T[]): void {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }
}


export function useGoogleImageSearch(config = configService.config) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const searchImages = useCallback(async (searchTerm: string) => {
    setLoading(true);
    setError(null);
    
    try {
      const searchUrl = GoogleImageService.getSearchUrl(searchTerm, config);
      if (!searchUrl) {
        throw new Error('Invalid search term');
      }
      
      const results = await GoogleImageService.getImageSearchResults(searchUrl, config);
      return results;
    } catch (err) {
      setError(err instanceof Error ? err : new Error('An error occurred'));
      return [config.noImageFoundUrl];
    } finally {
      setLoading(false);
    }
  }, [config]);

  return {
    searchImages,
    loading,
    error
  };
}
