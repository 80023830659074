import { useEffect, useState } from "react";

export const Logs = () => {
  const [logs, setLogs] = useState<Array<Object>>([]);

  useEffect(() => {
    fetch('/logs')
    .then(response => response.text())
    .then(data => {
      setLogs(JSON.parse(data));
    });
  }, []);


  if (logs.length === 0) {
    return <div>No logs found</div>;
  }

  const columns = Object.keys(logs[0]);

  return <table className="table table-striped">
    <thead>
      <tr>
        {columns.map((column) => (
          <th key={column}>{column}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {logs.map((log, index) => (
        <tr key={index}>
          {columns.map((column) => (
            <td key={column}>{(log as any)[column]}</td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>;
};