import React, { useState } from 'react';
import { configService } from '../../services/config.service';
import classNames from 'classnames';
import './bubble.css';
import { uploadImageService } from '../../services/uploadImage.service';
import { GoogleImageService } from '../../services/googleImages.service';
import { Utterance, UtteranceType } from '../../models/utterance.model';
import { ConversationState, conversationStore } from '../../stores/conversation.store';
import { showAboutDialog } from '../about/About';
import { Zoom } from '../../Zoom/Zoom';

interface BubbleProps {
  utterance: Utterance;
}

const Bubble: React.FC<BubbleProps> = ({
  utterance,
}) => {
  const [currentImageUrl, setCurrentImageUrl] = useState(utterance.imageUrl);
  const [zoomed, setZoomed] = useState(false);

  const isAI = () => utterance.type === UtteranceType.FROM_AI || utterance.type === UtteranceType.LOADING_AI_IMAGE;
  const isHuman = () => utterance.type === UtteranceType.FROM_HUMAN || utterance.type === UtteranceType.LOADING_HUMAN_IMAGE;
  const isPrompt = () => utterance.type === UtteranceType.PROMPT_IMAGE;

  const { type } = utterance;
  const { state } = conversationStore;
  const utteranceIndex = conversationStore.utterances.indexOf(utterance);
  const { talkBackLimit } = configService.config;

  const showStory = type === UtteranceType.FROM_AI &&
    state === ConversationState.WAITING_FOR_INPUT &&
    (!talkBackLimit ||
      ((utteranceIndex
        - conversationStore.utterances.length
        + talkBackLimit + 1) > 0));

  const showThumbsUp = type === UtteranceType.FROM_AI &&
    state === ConversationState.WAITING_FOR_INPUT &&
    (!talkBackLimit ||
      ((utteranceIndex
        - conversationStore.utterances.length
        + talkBackLimit + 1) > 0));

  const showThumbsDown = showThumbsUp; // && utteranceIndex > 0;

  const bubbleClasses = classNames('bubble', {
    'align-left': isAI(),
    'align-right': isHuman() || isPrompt()
  });

  const nextImage = () => {
    const urls = utterance.imageUrls ?? [];
    if (urls.length > 1) {
      const nextIndex = ((utterance.imageIndex ?? 0) + 1) % urls.length;
      utterance.imageIndex = nextIndex;
      setCurrentImageUrl(urls[nextIndex]);
    }
  };

  const onThumbsUp = () => {
    conversationStore.handleUserSelectImage(utterance.imageUrl, true, false, 'thumbsUp');
  }

  const onThumbsDown = () => {
    const indexUtterance = conversationStore.utterances.indexOf(utterance);
    // if (indexUtterance >= 0) {
      conversationStore.handleUserSelectImage(conversationStore.utterances[indexUtterance].imageUrl, true, true, 'thumbsDown');
    // }
  }

  const onLoad = () => {
    if (utterance.type === UtteranceType.FROM_AI) {
      GoogleImageService.markAsUsed(utterance.imageUrl);
    }
    setCurrentImageUrl(utterance.imageUrl);
  }

  const onError = () => {
    if (utterance.type === UtteranceType.FROM_AI) {
      GoogleImageService.markAsUsed(utterance.imageUrl);
    }
    nextImage();
  }

  const onImageUpload = (e: any) => {
    uploadImageService.onImageChanged(e);
  }

  const handleSpeakerClick = () => {
    // console.log('onSpeechClick');
    showAboutDialog();
  }

  return (
    <div className={bubbleClasses}>
      {/* Speaker */}
      <h4 className="speaker">
        <img src={utterance.speakerIconUrl} alt="speaker" onClick={handleSpeakerClick}/>
      </h4>

      {/* Speech bubble with embedded image */}
      {(isAI() || isHuman()) && (
        <div className="speech">
          <img src={currentImageUrl} alt="conversation" onClick={() => setZoomed(true)} />

          {showThumbsUp && (
            <button className="up-button" onClick={onThumbsUp}>
              <img src="assets/smile.png" alt="thumbs up" />
            </button>
          )}

          {showThumbsDown && (
            <button className="down-button" onClick={onThumbsDown}>
              <img src="assets/poop.png" alt="thumbs down" />
            </button>
          )}

          {showStory && configService.config.showNextButton && (utterance.imageUrls ?? []).length > 1 && (
            <button className="next-image" onClick={nextImage}>&gt;</button>
          )}

          <img 
            src={utterance.imageUrl} 
            onLoad={onLoad}
            onError={onError}
            style={{ display: 'none' }}
            alt="hidden"
          />

          <Zoom imageUrl={currentImageUrl} isOpen={zoomed} onClose={() => setZoomed(false)} />
        </div>
      )}

      {/* Prompt bubble */}
      {isPrompt() && (
        <div className="prompt-image">
          <img src="assets/promptImage.png" alt="prompt" />
          <input
            id="imageUpload"
            name="image"
            type="file"
            accept="image/*"
            required
            onChange={onImageUpload}
            onClick={(e) => (e.currentTarget.value = '')}
          />
        </div>
      )}
    </div>
  );
};

export default Bubble;
