import React, { useEffect } from 'react';
import { Utterance, UtteranceType } from '../../models/utterance.model';
import { ConversationState, conversationStore } from '../../stores/conversation.store';
import Bubble from '../bubble/Bubble';
import { observer } from 'mobx-react-lite';
import './Grid.css';

interface GridProps {
  conversationStore: {
    utterances: Utterance[];
    state: ConversationState;
  };
}

let timeoutId: NodeJS.Timeout | null = null;

const scrollToBottom = () => {
  timeoutId && clearTimeout(timeoutId);
  
  timeoutId = setTimeout(() => {
  const container = document.querySelector('.bubble-scroll-container');
  const bubbleContainer = document.querySelector('.bubble-container');

  if (container && bubbleContainer) {
    const scrollPosition = bubbleContainer.scrollHeight - container.clientHeight + 21;
    container.scrollTo({
      top: scrollPosition,
      behavior: 'smooth'
    });
  }
  }, 100);
}

(window as any).scrollToBottom = scrollToBottom;

const Grid: React.FC<GridProps> = observer(() => {
  const promptUtterance = new Utterance(UtteranceType.PROMPT_IMAGE);
  const loadingHumanUtterance = new Utterance(UtteranceType.LOADING_HUMAN_IMAGE);
  const loadingAIUtterance = new Utterance(UtteranceType.LOADING_AI_IMAGE);

  // call scrollToBottom after each render
  useEffect(() => {
    scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationStore.utterances.length, conversationStore.state]);

  return (
    <div className="bubble-container grid-block vertical">
      {conversationStore.utterances.map((utterance, index) => (
        <div key={index} className="grid-content">
          <Bubble utterance={utterance} />
        </div>
      ))}

      {conversationStore.state === ConversationState.WAITING_FOR_INPUT && (
        <div className="grid-content">
          <Bubble utterance={promptUtterance} />
        </div>
      )}

      {conversationStore.state === ConversationState.LOADING_INPUT && (
        <div className="grid-content">
          <Bubble utterance={loadingHumanUtterance} />
        </div>
      )}

      {conversationStore.state === ConversationState.LOADING_AI && (
        <div className="grid-content">
          <Bubble utterance={loadingAIUtterance} />
        </div>
      )}
      <p>&nbsp;</p>
    </div>
  );
});

export default Grid;
