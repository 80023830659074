// Something said, either by a human or the AI
export enum UtteranceType {
  FROM_HUMAN,
  FROM_AI,
  PROMPT_IMAGE,
  LOADING_HUMAN_IMAGE,
  LOADING_AI_IMAGE
}

export class Utterance {
  constructor(type?: UtteranceType, imageUrls?: Array<string>) {
    this.type = type;
    this.imageUrls = imageUrls ? imageUrls : [];
    this.imageIndex = 0;
    if (type !== undefined) {
      switch (type) {
        case UtteranceType.FROM_AI:
          this.speakerName = 'Pictrola';
          this.speakerIconUrl = 'assets/cat-icon.png';
          break;

        case UtteranceType.FROM_HUMAN:
          this.speakerName = 'You';
          this.speakerIconUrl = 'assets/me.png';
          break;

        case UtteranceType.PROMPT_IMAGE:
          this.speakerName = 'You';
          this.speakerIconUrl = 'assets/me.png';
          this.imageUrls = ['assets/uploadPrompt.png'];
          break;

          case UtteranceType.LOADING_AI_IMAGE:
          this.speakerIconUrl = 'assets/cat-icon.png';
          this.imageUrls = ['/assets/loading.gif'];
          break;

          case UtteranceType.LOADING_HUMAN_IMAGE:
          this.speakerIconUrl = 'assets/me.png';
          this.imageUrls = ['/assets/loading.gif'];
          break;
      }
    }
  }

  get imageUrl(): string {
    return this.imageUrls?.[this.imageIndex ?? 0] ?? '';
  }

  type?: UtteranceType;
  speakerIconUrl?: string;
  speakerName?: string;
  imageUrls?: Array<string>;
  imageIndex?: number;
  supportStory?: boolean;
  metadata?: any;
  text?: string;
}
