import axios from 'axios';
import { makeObservable, observable, runInAction } from 'mobx';

export function makeAbsoluteUrl(url: string) {
  if (url.startsWith('http')) {
    return url;
  }
  return window.location.origin + url;
}

export class ConfigService {

  inited: boolean = false;

  private _config: any = {
    'excludedConcepts': [
      'sfw',
      'people',
      'one',
      'travel',
      'portrait',
      'facial expression',
      'food',
      'no person',
      'Food & Beverages',
      'blank',
      'adult',
      'vehicle',
      'transportation system',
      'animals',
      'vehicle',
      'recreation',
      'isolated',
      'wear'
    ],
    'keywordLimit': 8,
    'shuffleKeywordsFrom': 2,
    'shuffleKeywordsTo': 8,
    'noImageFoundUrl': '/assets/huh.jpg',
    'numberImageResults': 3,
    'maxImageDimensions': 200
  };

  private _excludedConceptSet: Record<string, boolean> = {};

  constructor() {
    makeObservable(this, {inited: observable});
    // load config file
    axios.get('/config.json')
      .then((response) => {
        this._config = response.data;

        // allow overriding using URL params
        const urlParams = this.parseQueryString();
        for (const key of Object.keys(urlParams)) {
          let val: any = urlParams[key];
          if (val === 'true' || val === 'false') {
            val = (val === 'true');
          } else if (! isNaN(parseFloat(val))) {
            val = parseFloat(val);
          }
          this._config[key] = val;
          // console.log('Override ' + key + ' with ' + urlParams[key]);
        }

        this._config.noImageFoundUrl = makeAbsoluteUrl(this._config.noImageFoundUrl);

        if (response.data.hasOwnProperty('excludedConcepts')) {
          const excludedConceptSet = this._excludedConceptSet;
          Object.keys(response.data.excludedConcepts).forEach((concept) => {
            excludedConceptSet[concept.toLowerCase()] = true;
          });
        }
        runInAction(() => {
          this.inited = true;
        });
      })
      .catch((error) => {
        console.error('Error loading config:', error);
      });
  }

  public get config(): Readonly<{
    excludedConcepts: Array<string>,
    keywordLimit: number,
    shuffleKeywordsFrom: number,
    shuffleKeywordsTo: number,
    numberImageResults: number,
    noImageFoundUrl: string,
    maxImageDimensions: number,
    shuffleImageResults: boolean,
    modelMultipliers: Record<string, number>,
    keywordOffsetMin: number,
    keywordOffsetMax: number,
    negatedKeywordProbabilities: Array<number>,
    maxNegatedKeywords: number,
    dupKeywordLimit: number,
    showNextButton: boolean,
    talkBackLimit: number,
    searchImageSizeXLarge: boolean,
    allowedImageExtensions: Array<string>
  }> {
    return this._config;
  }

  public get excludedConceptSet(): Record<string, boolean> {
    return this._excludedConceptSet;
  }

  private parseQueryString(queryString?: string): Record<string, string> {
    // if the query string is NULL
    if (! queryString) {
        queryString = window.location.search.substring(1);
    }

    const params: Record<string, string> = {};

    const queries = queryString.split('&');

    queries.forEach((indexQuery: string) => {
        const indexPair = indexQuery.split('=');
        const queryKey = decodeURIComponent(indexPair[0]);
        const queryValue = decodeURIComponent(indexPair.length > 1 ? indexPair[1] : '');

        params[queryKey] = queryValue;
    });

    return params;
  }
}

export const configService = new ConfigService();